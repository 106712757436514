@use 'sass:math';

.media-video-wrapper {
	position: relative;
	padding-bottom: 56.25%; /* 16:9 */
	height: 0;
	clear: both;
	overflow: hidden;
	.typebase & {
		margin: $internal-spacing 0 0 0;
	}
	object,embed,iframe  {
		position: absolute;
		top: 0;
		left: 0;
		width: 100%;
		height: 100%;
	}
}

.block {
	object,embed,iframe  {
		width: 100%;
	}
}

.wp-caption, .media-figure {
    margin: $internal-spacing 0 0 0;
    &:first-child {
    	margin-top: 0;
    }
    img {
        @extend .max-width;
    }
}

.wp-caption-text, .media-figure__caption {
    padding: 10px 0;
    font-size: rem($typeMe);
    display: inline-block;
    line-height: 1.5;
    color: $limestone_20;
}

.alignleft, .left {
    float: left;
    margin-right: $internal-spacing;
    img {
        @extend .max-width;
    }
}

.alignright, .right {
    float: right;
    margin-left: $internal-spacing;
    img {
        @extend .max-width;
    }
}

.aligncenter {
	float: none;
	margin: 0 auto;
	img {
        @extend .max-width;
    }
}

iframe.wide {
	display: block;
    margin-top: 20px;
    max-width: 100%;
    width: 100%;
    // height: 641px;
}