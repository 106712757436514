// @import url('https://fonts.googleapis.com/css?family=Lato:400,700');

@font-face {
    font-family: 'Effra Trial Regular';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Trial Regular'), url('../fonts/Effra_Trial_Rg.woff') format('woff');
    // src: url('../fonts/Effra_Trial_Rg.woff') format('woff');
}


 @font-face {
    font-family: 'Effra Trial Bold';
    font-style: normal;
    font-weight: normal;
    src: local('Effra Trial Bold'), url('../fonts/Effra_Trial_Bd.woff') format('woff');
    // src: url('../fonts/Effra_Trial_Bd.woff') format('woff');
}


$fontBrand:		"Effra Trial Regular", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
$fontHeading:	"Effra Trial Bold", "Helvetica Neue", HelveticaNeue, Helvetica, Arial, sans-serif;
$fontMono:      monospace!default;

// Font weights (can be numerical or actual font files)
// ------------------------------------------------------------------

$fontThin:        100!default;
$fontUltraLight:  200!default;
$fontLight:       300!default;
$fontNormal:      400!default;
$fontMedium:      500!default;
$fontSemiBold:    600!default;
$fontBold:        700!default;
$fontExtraBold:   800!default;
$fontBlack:       900!default;


// Font defaults
// ------------------------------------------------------------------

$fontDefault:   $fontBrand!default;
$fontHeading:   $fontBrand!default;