@use 'sass:math';

.block {
	margin-top: $internal-spacing*2;
	clear: both;
	display: block;
	@include pie-clearfix;
	&:first-of-type {
		margin-top: 0;
	}
	&--form {
		padding: $internal-spacing $internal-spacing*1.5;
		background-color: $limestone_60;
		border-radius: $border-radius;
	}
}

.block__heading {
	@extend .heading;
    line-height: 1.3;
    font-size: rem($typeXXL);
    margin-bottom: $internal-spacing;
}

.block__intro {
	margin-bottom: $internal-spacing;
}

.block--divider {
	border-top: 1px solid $limestone_40;
	padding-top: $internal-spacing;
}

.block__heading__icon {
	background-color: $limestone_60;
	text-align: center;
	width: 44px;
	height: 44px;
	float: left;
	margin-right: $internal-spacing*.5;
	border-radius: 50%;
	top: -5px;
	position: relative;
	svg {
		width: 24px;
		height: 24px;
		position: relative;
		top: -1px;
		color: $grasslands_20;
	}
}