@use 'sass:math';

.form {
	position: relative;
	// background-color: $skies_20;
	// padding: $internal-spacing;
	// border-radius: $border-radius;
	&--spaced {
		margin: $internal-spacing 0;
	}
}

.fieldset {
	border: none;
	padding: 0;
	margin-bottom: $internal-spacing;
	&:last-child {
		margin-bottom: 0;
	}
	legend {
		@extend .heading;
		font-size: rem($typeL);
		margin-bottom: 12px;
		position: relative;
	}
}

// Labels
.form-label {
	font-size: rem($typeMe);
	font-family: $fontHeading;
	color: $cLabel;
	margin-bottom: 12px;
	position: relative;
	&--hidden {
		@include a11yhide;
	}
	// svg {
	// 	width: 15px;
	// 	height: 15px;
	// 	// display: inline-block;
	// 	// margin-left: 0px;
	// 	position: absolute;
	// 	right: -20px;
	// 	top: 0;
	// }
}

// .form-reset {
// 	display: inline-block;
// 	clear: left;
// 	font-size: rem($typeS);
// 	margin-top: $internal-spacing;
// 	color: $cLabel;
// }

// Form field wrapper
.form-field,
.gfield {
	position: relative;
	@include pie-clearfix;
	&--spaced {
		padding-top: math.div($internal-spacing,1.5);
	}
	&--error {
		.form-item {
			border-color: $cError;
			&:focus {
				border-color: $cError;
			}
		}
		.form-label {
			color: $cError;
		}
	}
	&--select-button {
		@include pie-clearfix;
		.form-field__select {
			float: left;
			position: relative;
			select {
				border-top-right-radius: 0;
				border-bottom-right-radius: 0;
			}
		}
		.button {
			float: left;
			border-top-left-radius: 0;
			border-bottom-left-radius: 0;
		}
	}
	.hint {
		font-size: rem($typeS);
		padding-top: 10px;
		clear: left;
		display: block;
	}
	&__error__message {
		color: $cError;
		display: none;
		font-size: rem($typeXS);
		padding-top: 6px;
		clear: left;
		&--visible {
			display: block;
		}
	}
	&__pair {
		@include respond-to($vpC) {
			width: 49.5%;
			margin-left: 1%;
			display: inline-block;
			margin-bottom: 0;
			float: left;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	&__triplet {
		@include respond-to($vpC) {
			width: 32.5%;
			margin-bottom: 0;
			margin-left: 1.25%;
			display: inline-block;
			margin-bottom: 0;
			float: left;
			&:first-child {
				margin-left: 0;
			}
		}
	}
	.hint {
		font-size: rem($typeXS);
		padding-top: 6px;
		clear: left;
		display: block;
	}
}

// Standard form elements
.form-item {
	padding: 10px;
	display: block;
	border: $strokeWidth solid $cBorder;
	appearance: none;
	background-color: #fff;
	height: $formItem;
	width: 100%;
	font-size: 16px; // Stop IOS doing the zoom thing, the Git!
	border-radius: $btnRadius;
	margin-top: 6px;
	&::focus-inner {
		border: 0;
		padding: 0;
	}
	&:focus {
		outline: none;
		border-color: $limestone_10;
	}
	&--small {
		height: math.div($formItem,1.25);
	}
}

// Turn off spinners for number input field, as flipping ugly
.form-item[type=number]::-webkit-inner-spin-button,
.form-item[type=number]::-webkit-outer-spin-button {
	appearance: none;
}

// Min height to text area
textarea.form-item {
	min-height: 200px;
}

.form-meta {
	font-size: rem($typeMe);
	margin-top: $internal-spacing;
}

// Radio and Checkboxes baseline
// -----------------------

.form-field__radiobuttons,
.form-field__checkboxes {
	margin-top: 6px;
	label {
		position: relative;
		top: 0;
		vertical-align: middle;
		font-family: $fontDefault;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input {
		float: left;
		margin-right: 12px;
		vertical-align: middle;
		&:after {
			clear: left;
			content: "";
			display: block;
			height: 0;
		}
	}
	input[type="radio"],
	input[type="checkbox"] {
		width: 16px;
		height: 16px;
		position: relative;
		top: 6px;
	}
	input[type="radio"] {
		width: 16px;
		height: 16px;
		position: relative;
	}
}

// Select baseline
// -----------------------

.form-field__select {
	// background-color: #fff;
	margin-top: 6px;
	select, .select2-container {
		width: 100%;
		display: block;
		height: $formItem;
		padding: 0px 5px 0 5px !important;
		vertical-align: middle;
		border: $strokeWidth solid $cBorder;
		appearance: none;
		-moz-appearance: window; // Hides firefox native select arrow
		background: url("data:image/svg+xml;utf8,<svg version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink' width='24' height='24' viewBox='0 0 24 24'><path fill='%23444' d='M7.406 7.828l4.594 4.594 4.594-4.594 1.406 1.406-6 6-6-6z'></path></svg>") #fff no-repeat right 47%;
		background-origin: content-box;
		background-size: 22px 19px;
		background-position: 98% 50%;
		border-radius: 0;
		font-size: 16px;
		line-height: 20px;
		border-radius: $btnRadius;
		&:hover, &:focus {
			border-color: $limestone_10;
			cursor: pointer;
		}
		&:focus {
			outline: none;
		}
		&::-ms-expand {
			display: none;
		}
		.formfieldError & {
			border-color: $cError;
		}
		option {
			max-width: 90%;
		}
	}
}


// Hide Captcha Badge
.grecaptcha-badge { visibility: hidden; }
.grecaptcha-badge { opacity: 0; }


// Date input
// .form-field__date {
// 	font-size: rem($typeMe);
// 	input {
// 		width: auto;
// 	}
// 	.form-field__triplet & {
// 		input {
// 			width: 100%;
// 		}
// 	}
// }

// mailchimp hack
//
// Legacy hack
// .yks-mailchimpFormDivRowLabel {
// 	text-transform: lowercase;
// }
// .yks-mailchimpFormDivRowLabel:first-letter {
// 	text-transform: uppercase;
// }
// #mailchimp-form input[type="text"],
// #mailchimp-form input[type="email"] {
// 	@extend .form-item;
// 	background: white;
// }
// #mailchimp-form span[class*="label"] {
// 	display: block;
// 	@extend .form-label;
// }
// .mailchimp-p {
// 	margin-bottom: 0.5em;
// }

// Mailchimp archive list

// .campaign {
// 	margin-bottom: $internal-spacing;
// 	font-size: rem($typeS);
// 	a {
// 		display: inline-block;
// 		clear: left;
// 		font-size: rem($typeM)
// 	}
// }

/* Gravity forms */

.gform_heading {
	font-family: $fontHeading!important;
	font-size: rem($typeL)!important;
	margin-bottom: 12px!important;
	position: relative!important;
}

.gsection_title {
	font-family: $fontHeading!important;
	font-size: rem($typeXL)!important;
	// margin-bottom: 12px!important;
}

.gform-field-label,
.gform-field-label--type-sub,
.gfield_description {
	font-size: rem($typeMe)!important;
	font-family: $fontHeading!important;
	color: $cLabel!important;
}

.gform-field-label--type-sub,
.gfield_description,
.gform-field-label--type-inline,
.gsection_description {
	font-family: $fontBrand!important;
	font-size: rem($typeMe)!important;
}

.gform_validation_errors {
	background-color: $rocky_40!important;
	border: 2px solid $rocky_20!important;
	ol,
	li {
		color: $black!important;
	}
}

.gform_submission_error,
.gform_validation_error_link {
	font-size: rem($typeMe)!important;
	color: $black!important;
}

.validation_message,
.gfield_validation_message,
.gfield_required {
	color: $cError!important;
}

.gfield_error input {
	border-color: $cError!important;
}

.gform_button {
	font-size: rem($typeMe)!important;
	line-height: $btnDefault!important;
	background-color: $cButtonPrimary!important;
	transition-duration: 0.1s!important;
	color: $black!important;
	border-radius: $btnRadius!important;
	border: 1px solid $sands_20!important;
	font-family: $fontHeading!important;
	padding: 0 $btnPadding!important;
	background: $sands_20!important;
	border-color: $sands_10!important;
	@include link-active-styles {
		background: $sands_30!important;
	}
}

.gform_confirmation_message {
	padding: $internal-spacing*.5 $internal-spacing!important;
	position: relative!important;
	background-color: $grasslands_40!important;
	border: 2px solid $grasslands_20!important;
	color: $cHeading!important;
	border-radius: $border-radius!important;
	font-size: rem($typeMe)!important;
}

body .gform_wrapper .gform_body input,
body .gform_wrapper .gform_body textarea {
    font-size: 16px;
}

