
// Brand colours
// ------------------------------------------------------------------

// Filter CSS Generator - https://codepen.io/sosuke/pen/Pjoqqp

$heathlands_40: #F8BED6;
$heathlands_30: #F99FC9;
$heathlands_20: #FC69AC;
$heathlands_10: #CE0F69;

$sands_40: #F5E1A4;
$sands_30: #FBDE40;
$sands_20: #F1C400;
$sands_10: #D69A2D;

$skies_40: #99D6EA;
$skies_30: #71C5E8;
$skies_20: #009CDE;
$skies_10: #008AD8;

$woodlands_40: #98D8A3;
$woodlands_30: #67B171;
$woodlands_20: #2E8840;
$woodlands_10: #00491E;

$limestone_60: #F3F4F4;
$limestone_50: #EBEBEB;
$limestone_40: #D0D3D4;
$limestone_30: #BBBCBC;
$limestone_20: #898D8D;
$limestone_10: #545859;

$moors_40: #DECDE7;
$moors_30: #AD96DC;
$moors_20: #825DC7;
$moors_10: #500878;

$rocky_40: #FCC89B;
$rocky_30: #FEAD77;
$rocky_20: #FF8F1C;
$rocky_10: #E35205;

$ocean_rivers_40: #C3D7EE;
$ocean_rivers_30: #5E93DB;
$ocean_rivers_20: #0857C3;
$ocean_rivers_10: #002D72;

$grasslands_40: #E0EC89;
$grasslands_30: #B7DB57;
$grasslands_20: #509E2F;
$grasslands_10: #257226;
$grasslands_00: #3A4427;

$white: #fff;
$black: #000;


// Non-brand feedbacks
// ------------------------------------------------------------------

$cError: $rocky_10;
$cSuccess: $grasslands_30;
$cAlert: $sands_40;


// Map for easy assignment loops + swatches
// These must match the colors above
// ------------------------------------------------------------------

$colors: (
	heathlands_40: $heathlands_40,
	heathlands_30: $heathlands_30,
	heathlands_20: $heathlands_20,
	heathlands_10: $heathlands_10,
	sands_40: $sands_10,
	sands_30: $sands_10,
	sands_20: $sands_20,
	sands_10: $sands_10,
	skies_40: $skies_40,
	skies_30: $skies_30,
	skies_20: $skies_20,
	skies_10: $skies_10,
	woodlands_40: $woodlands_40,
	woodlands_30: $woodlands_30,
	woodlands_20: $woodlands_20,
	woodlands_10: $woodlands_10,
	limestone_60: $limestone_60,
	limestone_50: $limestone_50,
	limestone_40: $limestone_40,
	limestone_30: $limestone_30,
	limestone_20: $limestone_20,
	limestone_10: $limestone_10,
	moors_40: $moors_40,
	moors_30: $moors_30,
	moors_20: $moors_20,
	moors_10: $moors_10,
	rocky_40: $rocky_40,
	rocky_30: $rocky_30,
	rocky_20: $rocky_20,
	rocky_10: $rocky_10,
	ocean_rivers_40: $ocean_rivers_40,
	ocean_rivers_30: $ocean_rivers_30,
	ocean_rivers_20: $ocean_rivers_20,
	ocean_rivers_10: $ocean_rivers_10,
	grasslands_40: $grasslands_40,
	grasslands_30: $grasslands_30,
	grasslands_20: $grasslands_20,
	grasslands_10: $grasslands_10,
	grasslands_00: $grasslands_00
);

// Assign colours to declarative variables
// ------------------------------------------------------------------

// Actions
$cAction: $grasslands_10;
$cActionRespond: $ocean_rivers_10;

// Buttons
$cButtonPrimary: $sands_40;

// Default text colors
$cHeading: $grasslands_00;
$cText: $grasslands_00;

// Form colors
$cLabel: $limestone_10;
$cFormNotes: $limestone_10;
$cFocus: $limestone_10;

// Misc. color assignments
$cBorder: $limestone_30;
