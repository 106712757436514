@use 'sass:math';


// Type scale
// ------------------------------------------------------------------

$scaleRoot: 				10;
$lineheightRoot: 			18;
$lineheightBase: 			math.div($lineheightRoot, $scaleRoot);

$typeXXXL: 					$scaleRoot*4.8;
$typeXXLLL: 				$scaleRoot*4.0;
$typeXXLL: 					$scaleRoot*3.6;
$typeXXL: 					$scaleRoot*3.0;
$typeXL: 					$scaleRoot*2.6;
$typeL: 					$scaleRoot*2.0;
$typeM:						$scaleRoot*1.8;
$typeMe:					$scaleRoot*1.6;		// Me = 'median'. Generally the standard font size for the page
$typeS: 					$scaleRoot*1.5;
$typeXS: 					$scaleRoot*1.4;

// Declaritives
$typeDefault: 				$typeM;


// Functions and mixins
// ------------------------------------------------------------------

// Make pixel values into REMS
@function rem($pixels){
	@return math.div($pixels, $scaleRoot) + rem; // Usage = font-size: rem(xx);
}

// Tighten leading
@mixin leading_adjust($gapAdjust) {
	line-height:  $lineheightBase - (math.div($gapAdjust, $scaleRoot));
	margin-bottom: rem($lineheightBase + $gapAdjust);
}
