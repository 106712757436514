/* ::Print */
/* ------------------------------------------------------------ */

@use "sass:math";

@media print {

	
@import "abstracts/_color.scss";
@import "abstracts/_fonts.scss";
@import "abstracts/_measurements.scss";
@import "abstracts/_mixins.scss";
@import "abstracts/_typescale.scss";
@import "abstracts/_viewports.scss";
	@import 'global/_typography.scss';
	@import 'components/_forms.scss';
	@import 'components/_flexible-content.scss';
	@import 'components/_media.scss';

	// Base

	html {
		font-size: $scaleRoot + px;
		scroll-behavior: smooth;
		@media (max-width: $vpDeviceSafe) {
			-webkit-text-size-adjust: 100%;
		}
	}

	body {
		color: $black;
		font-family: $fontDefault;
		font-size: rem($typeMe);
		line-height: $lineheightBase;
		overflow-x: hidden;
		font-weight: $fontNormal;
		position: relative;
	}

	a,
	.fauxlink {
		text-decoration: none;
		cursor: pointer;
		color: $black;
		border-bottom: 2px solid $cAction;
		@include link-active-styles {
			border-bottom: 2px solid $cActionRespond;
		}
	}

	a::after {
		content: " (" attr(href) ")";
		font-size: rem($typeMe);
	}

	.skiplink 	{
		background: #fff;
		color: $cHeading;
		border: 0;
		display: block;
		font-size: 16px;
		line-height: 40px;
		padding: 0 20px;
		position: absolute;
		top: -60px;
		left: 0;
		transition: all ease-in-out 0.1s;
		&:focus,
		&:active {
			top: 0;
			z-index: 80;
		}
	}

	// Overide iOS telephone styling on links
	a[href^="tel"] {
		color: inherit;
		text-decoration: none;
	}

	// Further sensible resets
	strong, b {
		font-weight: $fontSemiBold;
		@include sharp-text;
	}

	input, textarea, select, button {
		font-family: $fontDefault;
	}

	pre {
		white-space: pre-wrap;
		overflow-x: auto;
		tab-size: 4;
		font-size: rem($typeDefault);
	}

	// Max with utl class
	.max-width {
		max-width: 100%;
		height: auto;
		display: block;
		img {
			max-width: 100%;
			height: auto;
			display: block;
		}
	}
	// Max with for copy
	// .max-meter {
	// 	width: 100%;
	// 	max-width: 800px;
	// 	display: inline-block;
	// 	&--centered {
	// 		display: block;
	// 		margin-left: auto;
	// 		margin-right: auto;
	// 	}
	// }

	// Full width utl class
	.full-width {
		width: 100%;
		height: auto;
		display: block;
	}

	// Hide recaptcha

	.grecaptcha-badge {
	    visibility: hidden;
	}

    body {
        counter-reset: page;
        font-size: percentage(math.div(10, 16));
        color: $black;
        background-color: #fff;
    }
    p,
    .campaign,
    .stat,
    .button {
		@extend .text;
		margin-top: rem($typeDefault);
		display: block;
	}
	ul,
	ol {
		@extend %textList;
		margin-top: rem($typeDefault);
	}
	dl, .dt, .dd {
		margin: rem($typeDefault*1.5) 0;
		@extend .description_list;
	}
	ul {
		@extend .unordered_list;
	}
	ol {
		@extend .ordered_list;
	}
	li {
		@extend %listlead;
	}
	img {
		display: block;
		max-width: 100%;
		height: auto;
		// margin-top: rem($typeDefault*4);
	}
	h1, h2, h3, h4, h5, h6 {
		@extend .heading;
		position: relative;
		margin-top: rem($typeDefault*2);
		@include leading_adjust(4);
		color: $black;
		strong {
			font-weight: $fontNormal;
		}
	}
	h1 {
		@extend .typeXXXL;
	}
	h2 {
		@extend .typeXXL;
	}
	h3 {
		@extend .typeXL;
	}
	h4 {
		@extend .typeL;
	}
	h5 {
		@extend .typeM;
	}
	h6 {
		@extend .typeMe;
	}

	// Quote

	.quote,
	blockquote,
	.typebase blockquote {
		border-top: 1px solid $limestone_40;
		border-bottom: 1px solid $limestone_40;
		padding: $internal-spacing*2 0;
		position: relative;
		margin: $internal-spacing*2 0;
		p,
		.quote__content {
			font-size: rem($typeL);
			margin-bottom: math.div($internal-spacing,2);
			line-height: 1.6;
		}
		cite,
		.quote__citation {
			margin-bottom: math.div($internal-spacing,2);
			font-size: rem($typeDefault);
			font-style: normal;
		}
	}

	// Hide things
    .header,
    .footer,
    .search,
    .m-profile-filter,
    #sb_instagram,
    .button--icon svg,
    .downloads__meta svg,
    .card__media,
    .navigation-sidebar__item svg,
    .navigation-jump__item svg,
    .link-list svg,
    .linklist__item svg,
    .hero__aside,
    .hero-section__media,
    .filter,
    .hero__icon,
    .tabs__input,
    .tabs__label,
    .tabs__tab--map,
    .modal,
    .hero__aside__list__item svg,
    .button-info,
    .button--print,
    .share,
    .hero__search,
    .skiplink {
        display: none;
    }

    .hero--route .hero__aside {
    	display: block;
    }

    .carousel {
    	display: none!important;
    }

    .page-meta__label {
    	font-family: $fontHeading;
    }

    // Heroes
    .hero__description,
    .hero-section__intro {
    	margin-bottom: $internal-spacing;
    	padding-bottom: $internal-spacing;
    	border-bottom: 1px solid $limestone_40;
    	p {
    		@extend .typeM;
    	}
    }

    // Flex content
    .block__heading,
    .section__heading {
    	@extend .heading;
    	margin-bottom: $internal-spacing;
    	color: $black;
    }

    .section__intro {
    	margin-bottom: $internal-spacing;
    }

    // Drawers
    .drawer__title {
	    @extend .heading;
	    color: $black;
	    font-size: rem($typeL);
	}

	// Cards
	.card {
		// border: 1px solid $limestone_40;
		display: inline-block;
		// border-radius: $border-radius;
		// margin-bottom: $internal-spacing;
		overflow: hidden;
	}
	.card__content {
		// padding: $internal-spacing;
	}
	.card__heading {
		margin-top: 0;
	}
	.card__subheading {
		margin-top: 0;
		font-size: rem($typeL);
		font-weight: $fontNormal;
		font-family: $fontDefault;
	}
	.card__meta {
		display: inline-grid;
		grid-template-columns: auto auto;
		grid-row-gap: 5px;
		grid-column-gap: 10px;
		list-style: none;
		padding-left: 0;
	}
	.card--person,
	.card--lion {
		padding-left: 0;
		.card__media {
			display: inline-block;
			float: left;
			img {
				max-width: 200px;
			}
		}
		.card__content {
			padding-left: $internal-spacing;
			float: left;
		}
	}

	.card--icon {
		background-image: none!important;
	}

	// Gallery
	.gallery {
		display: inline-grid;
		grid-template-columns: 1fr 1fr;
		grid-gap: $internal-spacing;
	}

	// Pagination
	.pagination {
	    overflow: hidden;
	    display: flex;
	    svg {
	    	display: none;
	    }
	}
	.pagination__item {
	    display: inline-block;
	    margin-right: 10px;
	    font-size: rem($typeL);
	}
	.pagination__item-link--active {
	    font-weight: $fontSemiBold;
	    background-color: $limestone_50;
	    padding: 4px 8px;
	}

	// Route
	.route-meta__item {
		p {
			display: inline-block;
			margin-right: 5px;
			&:first-child:after {
				content:": ";
			}
		}
	}

	// Search
	.listing-item__meta {
		list-style: none;
		padding-left: 0;
	}

	// Grids
    .grid-2up,
    .grid-3up,
    .grid-4up,
    .grid-5up,
    .grid-people,
    .listing-routes,
    .grid-3up-listing {
        display: grid;
		grid-template-columns: 1fr;
		grid-column-gap: $internal-spacing;
		grid-row-gap: $internal-spacing*3;
    }

    .home-hero-grid {
    	.home-hero-grid__heading br {
    		display: none;
    	}
    	.home-hero-grid__media {
    		display: none;
    	}
    }

    // .grid-2up-offset {
    //     .grid-2up__col {
    //         width: 56.25%;
    //         +.grid-2up__col {
    //             width: 43.75%;
    //             margin-left: 0;
    //         }
    //     }
    // }
}