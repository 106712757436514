@use 'sass:math';
@import 'viewports.scss';



// General spacing options
// ------------------------------------------------------------------

$internal-spacing: 20px;

// Grid
// ------------------------------------------------------------------

$gridSet: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr; // 12 column grid
$gridMax: 1400px;
$gridGap: 2%;
$gridRowGap: 0;
$gridBaseline: 8;

// Borders
// ------------------------------------------------------------------

$default-border-width: 1px;
$border-radius: 6px;

// Ratios
// ------------------------------------------------------------------

$ratioWidescreen: 56.25%;


// Buttons
// ------------------------------------------------------------------

$btnSmall:    30px;
$btnDefault:  35px;
$btnRadius:   5px;
$btnPadding:  15px;
$btnPaddingSmall: math.div($btnPadding, 1.25);


// Misc
// ------------------------------------------------------------------

$formItem: 40px;
$strokeWidth: 1px;